var Extra = Extra || {}

Extra.NewsLetter = {
    render: ({ Title, Message, Email }) => {
        return `
            <div class="thanks-message">
                <h3>${Title}</h3>
                <p class="icon-tick message">${Message}<span id="sender-email">${Email}</span></p>
            </div>
        `
    }
}